import Vue from "vue";
import Vuex from "vuex";
import Http from "@/common/js/http";
import Util from "@/common/js/util.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        info: null,
        menuShow: true,
        treeList: [],

        location: {
            lat: 24.90165238,
            lng: 118.60036234,
            address: "福建泉州"
        },

        menuList: [], // 菜单
        permissions: [], // 按钮

        fUrl: "/admin/home/company", // 默认的url
        screen:{
            regionId:undefined,
            wasteTId:undefined,
            unit:'-',
        }
    },
    mutations: {
        setInfo(state, obj) {
            state.info = obj;
        },
        setMenuShow(state, b) {
            if (b !== undefined) {
                state.menuShow = !state.menuShow;
            } else {
                state.menuShow = !!b;
            }
        },
        setPermission(state, perms) {
            state.permissions = perms;
        },
        addTree(state, list) {
            state.treeList = list;
        },
        resetTree(state) {
            state.treeList = [];
        },

        setMenuTree(state, obj) {
            state.menuList = obj.menu;
        },
        clearState(state) {
            state.info = null;
            state.menuShow = true;
            state.treeList = [];
            state.menuList = [];
        },
        setFUrl(state, url) {
            state.fUrl = url;
        },
        setScreen(state, obj){
            Object.keys(obj).forEach(key=>{
                state.screen[key] = obj[key]
            })
        }
    },
    getters: {
        userInfoGet(state) {
            return state.info;
        },
        userId(state) {
            if (state.info) {
                return state.info.userId;
            } else {
                return "";
            }
        },
        getTree(state) {
            var list = state.treeList;
            return list;
        },
        screenWasterTId(state){
            return state.screen.wasteTId
        },
        screenRegionId(state){
            return state.screen.regionId
        },
        screenUnit(state){
            return state.screen.unit == 'kg' ? '吨' : state.screen.unit
        }
    },
    actions: {
        getInfo({ commit }) {
            return new Promise((resolve, reject) => {
                let url = "/sys/user/getCurrentUser";
                Http.post(url, {})
                    .then(data => {
                        commit("setInfo", data.detail);
                        Util.setStor("iRubbishAdminInfo", data.detail);
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        //左侧树菜单
        getMenuTree({ commit }) {
            return new Promise((resolve, reject) => {
                let url = "/sys/role/pageQueryMenu";
                Http.post(url, {
                    sysMenu: {
                        tType: "1"
                    },
                    pageIndex: 0,
                    pageSize: 0
                })
                    .then(({ detail }) => {
                        // console.log(detail);
                        // function arrange(list) {
                        //     let newList = [];
                        //     list.forEach(item => {
                        //         if (item.tType != "2") {
                        //             newList.push(item);
                        //         }
                        //     });
                        //     newList.forEach(item => {
                        //         item.text = item.name;
                        //         if (item.children && item.children.length) {
                        //             item.children = arrange(item.children);
                        //         }
                        //     });
                        //     return newList;
                        // }
                        // detail = arrange(detail);
                        // console.log(detail)
                        let menuList = [],
                            permissions = [];
                        function splitPermission(menus, p) {
                            menus.forEach(m => {
                                if (m.data.tType !== "2") {
                                    const copy = {
                                        type: m.type,
                                        data: m.data
                                    };
                                    if (copy.data.pMenuId === "wastesScreen") {
                                        copy.blank = true;
                                        copy.data.url = "/#" + copy.data.url;
                                    }
                                    if (!p) {
                                        menuList.push(copy);
                                        commit("setFUrl", copy.data.url);
                                    } else {
                                        if (!p.children) {
                                            p.children = [];
                                        }
                                        p.children.push(copy);
                                    }

                                    if (m.children) {
                                        splitPermission(m.children, copy);
                                    }
                                } else {
                                    permissions.push(m);
                                }
                            });
                        }
                        splitPermission(detail);

                        commit("setMenuTree", { menu: menuList });
                        commit("setPermission", permissions);
                        resolve(detail);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
});
