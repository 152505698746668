<template>
	<el-col class="query_item_com" :span="colSpan">
		<el-form-item :label-width="formLebelWidth" :label="label" :prop="prop">
			<slot></slot>
		</el-form-item>
	</el-col>
</template>
<script>
export default {
	name: 'jy-query-item',
	props: {
		span: {
			type: [Number, String],
			default: 1
		},
		label: {
			type: String,
			default: ''
		},
		labelWidth: {
			type: [Number, String],
			default: ''
		},
		prop: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			defSpan: 6,
		}
	},
	computed: {
		colSpan: function() {
			var unit = this.defSpan;
			let span = Number(this.span) || 0;
			return Math.round(unit * span);
		},
		formLebelWidth: function() {
			let n = Number(this.labelWidth.replace('px', '')) || '';
			return n ? n + 'px' : '';
		},
	},
	created() {
		this.setDefSpan();
		window.$(window).resize(() => {
			this.setDefSpan();
		});
	},
	methods: {
		setDefSpan() {
			var run = () => {
				let elWidth = window.$(this.$parent.$el).width();
				// let itemNum = 0;
				// this.$parent.$children.forEach(item => {
				// 	if (item.$options.name == this.$options.name) {
				// 		itemNum++;
				// 	}
				// });
				if (elWidth <= 900) {
					this.defSpan = 8;
				} else {
					this.defSpan = 6;
				}
			}

			if (window.$(this.$parent.$el).width()) {
				run();
			} else {
				this.$nextTick(() => {
					run();
				});
			}

		},
	},
}

</script>
<style lang="scss">
.query_item_com {
	height: 50px;

	.el-select,
	.el-date-editor.el-input,
	.el-date-editor.el-input__inner,
	.el-date-editor--datetimerange.el-input,
	.el-date-editor--datetimerange.el-input__inner {
		width: 100%;
	}

}

</style>
