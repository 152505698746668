<template>
    <div class="jy-homeSum">
        <div class="homelayout">
            <div
                class="homeitem"
                v-for="one in list"
                v-bind:key="one.id"
                v-bind:class="{ hover: one.canClick === undefined ? canClick : one.canClick }"
                v-on:click="itemClick(one)"
            >
                <slot :data="one">
                    <img :src="one[icon]" class="img" />
                </slot>
                <div class="text">
                    <div class="number">
                        {{ one[num] }}
                        <span class="unit">{{ one[unit] }}</span>
                        <span class="percent">{{ one[percent] ? ` 同比去年${one[percent]}%` : '' }}</span>
                    </div>
                    <div>{{ one[info] }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "jy-homesum",
        data() {
            return {};
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                }
            },
            canClick: {
                type: Boolean,
                default: false
            },
            props: {}
        },
        computed: {
            info: function () {
                if (this.props && this.props.info) {
                    return this.props.info;
                } else {
                    return "info";
                }
            },
            num: function () {
                if (this.props && this.props.num) {
                    return this.props.num;
                } else {
                    return "num";
                }
            },
            unit: function () {
                if (this.props && this.props.unit) {
                    return this.props.unit;
                } else {
                    return "unit";
                }
            },
            icon: function () {
                if (this.props && this.props.icon) {
                    return this.props.icon;
                } else {
                    return "icon";
                }
            },
            percent: function() {
                if (this.props && this.props.percent) {
                    return this.props.percent;
                } else {
                    return "percent";
                }
            }
        },
        methods: {
            itemClick(one) {
                if (one.canClick === undefined ? this.canClick : one.canClick) {
                    this.$emit("itemClick", one);
                }
            }
        }
    };
</script>
<style lang="scss" scoped="jy-homeSum">
    .jy-homeSum {
        box-shadow: #dcdcdc 0px 0px 7px;
        padding: 20px 20px;
        background: #fff;
        .homelayout {
            display: flex;
            flex-wrap: wrap;
            .homeitem {
                flex: 1;
                display: inline-flex;
                &.hover {
                    cursor: pointer;
                }
                .img {
                    height: 60px;
                }
                .text {
                    padding-left: 10px;
                    display: flex;
                    flex-direction: column;
                    .number {
                        flex: 1;
                        font-weight: bold;
                        font-size: 25px;
                        color: #5a5a5a;
                        + div {
                            color: #b3b8ce;
                            font-size: 16px;
                            font-weight: 600;
                        }

                        .unit {
                            font-size: 16px;
                        }

                        .percent {
                            font-size: 10px;
                        }
                    }
                }
                + .homeitem {
                    border-left: 1px solid #ebebeb;
                    padding-left: 20px;
                }
            }
        }
    }
</style>
