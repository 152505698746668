//mixin
import GlobalOld from './config/global'


// element set
import ElementUI from './config/element';
import { setButtonClick } from './config/element';
var elementSize = 'medium';
if (window.$(window).width() < 1400) {
	elementSize = 'small';
}

import components from './components/index.js'
import directives from './directive/index.js'

export default {
	install: function(Vue, option) {

		//mixin
		var Global = {
			...GlobalOld,
		};
		if (option && option.global) {
			Global = {
				...Global,
				...option.global
			};
		}
		var minxinObj = {
			data() {
				return {
					Global,
					global: Global
				}
			},
		};
		Vue.mixin(minxinObj);

		//components 组件
		components.forEach(function(one) {
			if (one.name) {
				Vue.component(one.name, one);
			}else{
				console.warn('有组件没有命名')
			}
		});

		//directives 指令
		directives.forEach(one=>{
			if(one.name){
				Vue.directive(one.name,one)
			}else{
				console.warn('有指令没有命名')
			}
		})

		//埋点
		if (Vue.prototype.$collect) {
			let collect = Vue.prototype.$collect
			if (collect.buttonClick) {
				setButtonClick(collect.buttonClick,collect.option)
			}
		}

		//element set
		Vue.use(ElementUI)
		Vue.prototype.$ELEMENT = {
			size: elementSize,
			zIndex: 3000
		};
	}
}
