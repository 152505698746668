<template>
	<div class="screen_common_block" v-bind:style="blockStyle">
		<div class="common_block" v-bind:style="blockStyle">
			<div class="corner"></div>
			<div class="corner"></div>
			<div class="corner"></div>
			<div class="corner"></div>
			<slot></slot>
		</div>
	</div>
</template>
<script type="text/javascript">
export default {
	name: 'jy-screen-box',
	props: {
		width: {
			type: [String, Number],
			default: ''
		},
		height: {
			type: [String, Number],
			default: '100%'
		},
	},
	data() {
		return {
			blockStyle: {},
		}
	},
	watch: {
		width: function() {
			this.setStyle();
		},
		height: function() {
			this.setStyle();
		},
	},
	created() {
		this.setStyle();
	},
	methods: {
		setStyle() {
			let obj = {};
			if (typeof this.width == 'string') {
				obj.width = this.width;
			} else {
				obj.width = this.width + 'px';
			}
			if (this.height) {
				if (typeof this.height == 'string') {
					obj.height = this.height;
				} else {
					obj.height = this.height + 'px';
				}
			}
			this.blockStyle = obj;
		},
	},
}

</script>
<style type="text/css" lang="scss" scoped="screen_common_block">
@import '../../config/variable.scss';

.screen_common_block {
	padding-top: $screen_block_margin;
	padding-left: $screen_block_margin;

	.common_block {
		position: relative;
		background: rgba(225, 255, 255, .05);
		border: 1px solid $screen_line_color_2;

		.corner {
			position: absolute;
			width: 20px;
			height: 20px;
			border: 2px solid $screen_line_color_1;

			&:nth-of-type(1) {
				top: 0;
				left: 0;
				border-right: none;
				border-bottom: none;
			}

			&:nth-of-type(2) {
				top: 0;
				right: 0;
				border-left: none;
				border-bottom: none;
			}

			&:nth-of-type(3) {
				bottom: 0;
				left: 0;
				border-right: none;
				border-top: none;
			}

			&:nth-of-type(4) {
				bottom: 0;
				right: 0;
				border-left: none;
				border-top: none;
			}
		}
	}
}

</style>
