import Vue from "vue";
import VueRouter from "vue-router";
import Util from "@/common/js/util";
import store from "./store";
import { MessageBox } from "element-ui";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "default",
        redirect: "admin/home"
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./views/login.vue")
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import("./views/admin/entry.vue"),
        redirect: "/admin/home",
        children: [
            {
                path: "home",
                name: "home",
                component: () => import("./views/admin/home/entry.vue"),
                redirect: "/admin/home/company",
                children: [
                    {
                        path: "company",
                        name: "company",
                        component: () => import("./views/admin/home/company/index.vue")
                    },
                    {
                        path: "depart",
                        name: "depart",
                        component: () => import("./views/admin/home/depart/index.vue")
                    }
                ]
            },
            {
                path: "order",
                name: "order",
                component: () => import("./views/admin/order/index.vue")
            },
            {
                path: "dispatch",
                name: "dispatch",
                component: () => import("./views/admin/dispatch/entry.vue"),
                children: [
                    {
                        path: "order",
                        name: "dOrder",
                        component: () => import("./views/admin/dispatch/order/index.vue")
                    },
                    {
                        path: "team",
                        name: "team",
                        component: () => import("./views/admin/dispatch/team/index.vue")
                    },
                    {
                        path: "track",
                        name: "track",
                        component: () => import("./views/admin/dispatch/track/index.vue")
                    },
                    {
                        path: "monotor",
                        name: "monotor",
                        component: () => import("./views/admin/dispatch/monotor/index.vue")
                    }
                ]
            },
            {
                path: "manageCenter",
                name: "manageCenter",
                component: () => import("./views/admin/manageCenter/entry.vue"),
                children: [
                    {
                        name: "record",
                        path: "record",
                        component: () => import("./views/admin/manageCenter/record/index.vue")
                    },
                    {
                        name: "inBound",
                        path: "inBound",
                        component: () => import("./views/admin/manageCenter/inBound/index.vue")
                    },
                    {
                        name: "outBound",
                        path: "outBound",
                        component: () => import("./views/admin/manageCenter/outBound/index.vue")
                    },
                    {
                        name: "stock",
                        path: "stock",
                        component: () => import("./views/admin/manageCenter/stock/index.vue")
                    }
                ]
            },
            {
                path: "settleCenter",
                name: "settleCenter",
                component: () => import("./views/admin/settleCenter/entry.vue"),
                children: [
                    {
                        path: "receiveAccount",
                        name: "receiveAccount",
                        component: () => import("./views/admin/settleCenter/receiveAccount/index.vue")
                    },
                    {
                        path: "receiveBill",
                        name: "receiveBill",
                        component: () => import("./views/admin/settleCenter/receiveBill/index.vue")
                    }
                ]
            },
            {
                path: "handleCenter",
                name: "handleCenter",
                component: () => import("./views/admin/handleCenter/index.vue")
            },
            {
                path: "serviceMap",
                name: "serviceMap",
                component: () => import("./views/admin/serviceMap/index.vue")
            },
            {
                path: "videoMonitor",
                name: "videoMonitor",
                component: () => import("./views/admin/videoMonitor/entry.vue"),
                children: [
                    {
                        path: "realTime",
                        name: "real",
                        component: () => import("./views/admin/videoMonitor/real/index.vue")
                    },
                    {
                        path: "history",
                        name: "history",
                        component: () => import("./views/admin/videoMonitor/history/index.vue")
                    }
                ]
            },
            {
                path: "finance",
                name: "finance",
                component: () => import("./views/admin/finance/entry.vue"),
                redirect: '/admin/finance/charge',
                children: [
                    {
                        path: "charge",
                        name: "charge",
                        component: () => import("./views/admin/finance/charge/index.vue")
                    },
                    {
                        path: "consumption",
                        name: "consumption",
                        component: () => import("./views/admin/finance/consumption/index.vue")
                    },
                    {
                        path: "deskAccount",
                        name: "deskAccount",
                        component: () => import("./views/admin/finance/deskAccount/index.vue")
                    }
                ]
            },
            {
                path: "basic",
                name: "basic",
                component: () => import("./views/admin/basic/entry.vue"),
                children: [
                    {
                        path: "driver",
                        name: "driver",
                        component: () => import("./views/admin/basic/driver/index.vue")
                    },
                    {
                        path: "vehicle",
                        name: "vehicle",
                        component: () => import("./views/admin/basic/vehicle/index.vue")
                    },
                    {
                        path: "equipmentManagement",
                        name: "device",
                        component: () => import("./views/admin/basic/device/index.vue")
                    },
                    {
                        path: "register",
                        name: "rubbishUnit",
                        component: () => import("./views/admin/basic/rubbishUnit/index.vue")
                    },
                    {
                        path: "useUnit",
                        name: "useUnit",
                        component: () => import("./views/admin/basic/useUnit/index.vue")
                    },
                    {
                        path: "handleUnit",
                        name: "handleUnit",
                        component: () => import("./views/admin/basic/handleUnit/index.vue")
                    },
                    {
                        path: "recycle",
                        name: "recycle",
                        component: () => import("./views/admin/basic/recycle/index.vue")
                    },
                    {
                        path: "materials",
                        name: "materials",
                        component: () => import("./views/admin/basic/materials/index.vue")
                    },
                    {
                        path: "distributionCenter",
                        name: "distributionCenter",
                        component: () => import("./views/admin/basic/distributionCenter/index.vue")
                    },
                    {
                        path: "rubbishClass",
                        name: "rubbishClass",
                        component: () => import("./views/admin/basic/rubbishClass/index.vue")
                    },
                    {
                        path: "weighbridge",
                        name: "weighbridge",
                        component: () => import("./views/admin/basic/weighbridge/index.vue")
                    }
                ]
            },
            {
                path: "statistics",
                name: "statistics",
                component: () => import("./views/admin/statistics/entry.vue"),
                children: [
                    {
                        path: "rubbishYear",
                        name: "rubbishYear",
                        component: () => import("./views/admin/statistics/rubbishYear/index.vue")
                    },
                    {
                        path: "rubbishMonth",
                        name: "rubbishMonth",
                        component: () => import("./views/admin/statistics/rubbishMonth/index.vue")
                    },
                    {
                        path: "rubbishDay",
                        name: "rubbishDay",
                        component: () => import("./views/admin/statistics/rubbishDay/index.vue")
                    },
                    {
                        path: "centerRubYear",
                        name: "centerRubYear",
                        component: () => import("./views/admin/statistics/centerRubYear/index.vue")
                    },
                    {
                        path: "centerRubMonth",
                        name: "centerRubMonth",
                        component: () => import("./views/admin/statistics/centerRubMonth/index.vue")
                    },
                    {
                        path: "regionRubYear",
                        name: "regionRubYear",
                        component: () => import("./views/admin/statistics/regionRubYear/index.vue")
                    },
                    {
                        path: "regionRubMonth",
                        name: "regionRubMonth",
                        component: () => import("./views/admin/statistics/regionRubMonth/index.vue")
                    },
                    {
                        path: "regionRubDay",
                        name: "regionRubDay",
                        component: () => import("./views/admin/statistics/regionRubDay/index.vue")
                    },
                    {
                        path: "orderCount",
                        name: "orderCount",
                        component: () => import("./views/admin/statistics/orderCount/index.vue")
                    }
                ]
            },
            {
                path: "systemSettings",
                name: "systemSettings",
                component: () => import("./views/admin/systemSettings/entry.vue"),
                redirect: "/admin/systemSettings/organizationManagement",
                children: [
                    {
                        path: "organizationManagement",
                        name: "organizationManagement",
                        component: () => import("./views/admin/systemSettings/organizationManagement.vue")
                    },
                    {
                        path: "roleManagement",
                        name: "roleManagement",
                        component: () => import("./views/admin/systemSettings/roleManagement.vue")
                    },
                    {
                        path: "accountManagement",
                        name: "accountManagement",
                        component: () => import("./views/admin/systemSettings/accountManagement.vue")
                    },
                    {
                        path: "logonLog",
                        name: "logonLog",
                        component: () => import("./views/admin/systemSettings/logonLog.vue")
                    },
                    {
                        path: "operationLog",
                        name: "operationLog",
                        component: () => import("./views/admin/systemSettings/operationLog.vue")
                    },
                    {
                        path: "dict",
                        name: "dict",
                        component: () => import("./views/admin/systemSettings/dictManagement.vue")
                    },
                    {
                        path: "information",
                        name: "information",
                        component: () => import("./views/admin/systemSettings/information.vue")
                    }
                ]
            }
        ]
    },
    {
        path: "/screen",
        name: "screen",
        component: () => import("./views/admin/screen/entry.vue"),
        children: [
            {
                path: "screen1",
                name: "screen1",
                component: () => import("./views/admin/screen/screen1/index.vue")
            },
            {
                path: "screen2",
                name: "screen2",
                component: () => import("./views/admin/screen/screen2/index.vue")
            },
            {
                path: "screen3",
                name: "screen3",
                component: () => import("./views/admin/screen/screen3/index.vue")
            }
        ]
    }
];

const router = new VueRouter({
    routes
});

//页面权限控制
function havePage(list, url) {
    let ishave = false;
    list.forEach(item => {
        if (item.url == url) {
            ishave = true;
        }
        if (item.children && item.children.length && !ishave) {
            ishave = havePage(item.children, url);
        }
    });
    return ishave;
}
//
function listTo(obj) {
    let u = "";
    if (obj.children && obj.children.length) {
        u = listTo(obj.children[0]);
    } else {
        u = obj.url;
    }
    return u;
}

const noTokenRouterNamList = ["login"];
router.beforeEach((to, from, next) => {
    let query = Util.getParam();
    if (query.token) {
        Util.setStor("token", query.token);
        let url = location.origin + location.pathname + "#/admin/home";
        location = url;
        return;
    }

    var token = window.localStorage.getItem("token");
    if (!token && !noTokenRouterNamList.includes(to.name)) {
        next("/login");
    } else {
        let isMenu = store.state.menuList && store.state.menuList.length;
        if ((!store.state.info || !isMenu) && token) {
            store.dispatch("getInfo").then(() => {
                store.dispatch("getMenuTree").then(() => {
                    let list = store.state.menuList;
                    let isHavePage = havePage(list, to.path);
                    if (isHavePage || noTokenRouterNamList.includes(to.name)) {
                        next();
                    } else {
                        let url = "";
                        if (list.length) {
                            url = listTo(list[0]);
                            next(url);
                        } else {
                            url = "/login";
                            if (from.name == "login") {
                                MessageBox({
                                    title: "提示",
                                    message: "你还没有任何权限，请联系管理员添加。",
                                    callback: () => {
                                        Util.removeStor("token");
                                    }
                                });
                            } else {
                                next(url);
                            }
                        }
                    }
                });
            });
        } else {
            let list = store.state.menuList;
            let isHavePage = havePage(list, to.path);
            if (isHavePage || noTokenRouterNamList.includes(to.name)) {
                next();
            } else {
                let url = "";
                if (list.length) {
                    url = listTo(list[0]);
                } else {
                    url = "/login";
                }
                next(url);
            }
        }
    }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch(err => err);
};

export default router;
