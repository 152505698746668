<template>
  <div class="com_img">
    <el-image :src="url||src" class="img_box" fit="contain">
      <div slot="error" style="height:100%;width:100%;">
        <div class="image_slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </div>
    </el-image>
  </div>
</template>
<script>
export default {
  props: {
    url: String,
    src:String
  }
}
</script>
<style scoped lang="scss">
$w:100px;
$h:100px;

.com_img {
  width: $w;
  height: $h;

  .img_box {
    width: 100%;
    height: 100%;

    .image_slot {
      width: 100%;
      height: 100%;
      background: #F5F7FA;
      text-align: center;
      font-size: 40px;
      color: #c4c4c4;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>