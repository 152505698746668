<template>
    <div id="app">
        <jy-top :noTopRoute="noTopRoute" :logo="getLogo" @logout="logout" :rightText="rightText"></jy-top>
        <keep-alive>
            <router-view />
        </keep-alive>
    </div>
</template>
<script>
    import logo from "@/assets/logo.png";
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                noTopRoute: ["login", "screen1", "screen2", "screen3"],
                logo
            };
        },
        computed: {
            rightText: function () {
                let text = "";
                if (this.$store.state && this.$store.state.info) {
                    text = this.$store.state.info.markNa;
                }
                return text;
            },
            ...mapGetters(["userInfoGet"]),
            getLogo() {
                var userInfo = this.userInfoGet || {};
                if (userInfo && userInfo.userId) {
                    return userInfo.userLogoImg || logo;
                } else {
                    userInfo = this.$util.getStor("iRubbishAdminInfo") || userInfo;
                }
                return userInfo.userLogoImg;
            }
        },
        methods: {
            logout() {
                let url = "/sys/user/loginOut";
                this.$http.post(url).then(() => {
                    this.$util.removeStor("token");
                    this.$store.state.info = null;
                    this.$store.state.treeList = [];
                    this.$store.state.menuList = [];
                    this.$router.push({ name: "login" });
                });
            }
        }
    };
</script>
<style lang="scss">
    html,
    body,
    #app {
        height: 100%;
    }
    #app {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }

    [class^="jy-"] {
        font-family: "jy" !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .jy {
        width: 1em;
        height: 1em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }

    ul.pop_select_ul {
        li {
            padding: 4px;
            @extend .hand;

            &:hover {
                background: #efefef;
            }
        }
    }
    .avatar-uploader{
        width: 100px;
        height:100px;
    }
</style>
