import ElementUI from 'element-ui';

//全局修改默认配置
ElementUI.Dialog.props.closeOnClickModal.default = false;

//button
export function setButtonClick(fun, option) {
	var oldClick = ElementUI.Button.methods.handleClick
	ElementUI.Button.methods.handleClick = function(e) {
		oldClick = oldClick.bind(this)
		oldClick(e)
		try {
			if (fun instanceof Function) {
				fun && fun(this, option)
			} else if (fun instanceof Array) {
				fun.forEach(one => {
					one(this, option)
				})
			}
		} catch (err) {
			console.err(err)
		}
	}
}

export default ElementUI;
