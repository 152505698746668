<template>
    <div class="login">
        <div class="left" v-bind:style="leftStyle"></div>
        <div class="right">
            <div class="msg_box">
                <div class="head">
                    <img v-bind:src="logo" />
                    <h1 class="topName" v-text="topName"></h1>
                    <h1 class="name" v-text="name"></h1>
                </div>
                <div class="center">
                    <el-form ref="loginForm" :model="form" :rules="formRules">
                        <el-form-item prop="userName">
                            <el-input v-model="form.userName" size="big" prefix-icon="el-icon-user" placeholder="请输入用户名"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input type="password" v-model="form.password" size="big" prefix-icon="el-icon-lock" placeholder="请输入密码" v-on:keyup.enter.native="login"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button :loading="buttonLoading" size="big" type="primary" style="width:100%;" @click="login">登&nbsp;&nbsp;录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="foot" style="display: none;">
                <!-- <p>福建精研智通信息科技有限公司 版权所有</p>
                <p>Copyright @ 2015 - 2019 闽ICP备18028974号</p> -->
                <p>Powered by GENYON</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'jy-login',
    props: {
        topName: {
            type: String,
            default: '新能源车辆智慧服务平台'
        },
        name: {
            type: String,
            default: '系统名称'
        },
        bg: {
            type: [String, Object],
            default: '',
        },
        logo: {
            type: [String, Object],
            default: '',
        },
        loading: {
            type: Boolean,
            default: undefined,
        }
    },
    data() {
        return {
            form: {
                userName: '',
                password: ''
            },
            formRules: {
                userName: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
            },
            leftStyle: {},
            hasSetLoading: false,
        };
    },
    model: {
        prop: 'loading',
        event: 'setLoading'
    },
    computed: {
        buttonLoading: {
            get: function() {
                return this.loading
            },
            set: function(val) {
                if (this.hasSetLoading) {
                    this.$emit('setLoading', val)
                } else {
                    this.loading = val
                }
            }
        },
    },
    watch: {
        bg: function() {
            this.setLeftStyle();
        }
    },
    created() {
        if (this.loading !== undefined) {
            this.hasSetLoading = true
        }
        this.setLeftStyle();
    },
    activated() {
        this.setLeftStyle();
    },
    methods: {
        login() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    this.buttonLoading = true
                    setTimeout(() => {
                        this.buttonLoading = false
                    }, 3000)
                    this.$emit('login', this.form)
                } else {
                    console.log('error submit!!')
                    return false;
                }
            });
        },
        setLeftStyle() {
            let obj = {
                background: 'url(' + this.bg + ') center center /cover no-repeat'
            }
            this.leftStyle = obj;
        },
    },
    components: {}
};

</script>
<style lang="scss" scoped>
.login {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;

    .left {
        flex-grow: 1;
    }

    .right {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 0 60px;

        >.msg_box {
            margin-top: -60px;
        }

        .head {
            h1 {
                color: #1977BE;

                &.topName {
                    font-size: 30px;
                    margin-top: 20px;
                }

                &.name {
                    margin: 20px 0;
                    font-size: 36px;
                }
            }
        }

        .center {
            width: 100%;
        }

        .foot {
            position: absolute;
            bottom: 5%;

            >p {
                font-size: 10px;
            }
        }
    }
}

</style>
