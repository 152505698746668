<template>
    <div class="admin_nav" :class="mode">
        <div class="nav_box">
            <el-menu
                router
                :default-active="this.$route.path"
                unique-opened
                :background-color="backgroundColor"
                :text-color="TextColor"
                :active-text-color="activeTextColor"
                :mode="mode"
            >
                <template v-for="one in menu">
                    <el-submenu :index="one.data.url" v-if="one.children && one.children.length" v-bind:key="one.data.menuId">
                        <template slot="title">
                            <i :class="one.data.icon" v-if="one.data.icon"></i>
                            <span>{{ one.data.name }}</span>
                        </template>
                        <template v-for="item in one.children">
                            <el-submenu :index="item.data.url" v-if="item.children && item.children.length" v-bind:key="item.data.menuId">
                                <template slot="title">
                                    <i :class="item.data.icon" v-if="item.data.icon"></i>
                                    <span>{{ item.data.name }}</span>
                                </template>
                                <template v-for="k in item.children">
                                    <el-menu-item :index="k.data.url" v-bind:key="k.data.menuId">
                                        <i :class="k.data.icon" v-if="k.data.icon"></i>
                                        <span slot="title">{{ k.data.name }}</span>
                                    </el-menu-item>
                                </template>
                            </el-submenu>
                            <el-menu-item :index="item.data.url" v-bind:key="item.data.menuId" v-else-if="!item.blank">
                                <i :class="item.data.icon" v-if="item.data.icon"></i>
                                <span slot="title">{{ item.data.name }}</span>
                            </el-menu-item>
                            <a :href="item.data.url" v-bind:key="item.data.menuId" target="_blank" class="nav_a" v-else>
                                <el-menu-item v-bind:key="item.data.menuId">
                                    <i :class="item.data.icon" v-if="item.data.icon"></i>
                                    <span>{{ item.data.name }}</span>
                                </el-menu-item>
                            </a>
                        </template>
                    </el-submenu>
                    <el-menu-item :index="one.data.url" v-bind:key="one.data.menuId" v-else-if="!one.blank">
                        <i :class="one.data.icon" v-if="one.data.icon"></i>
                        <span slot="title">{{ one.data.name }}</span>
                    </el-menu-item>
                    <a :href="one.data.url" v-bind:key="one.menuId" target="_blank" class="nav_a" v-else>
                        <el-menu-item v-bind:key="one.menuId">
                            <i :class="one.data.icon" v-if="one.data.icon"></i>
                            <span>{{ one.data.name }}</span>
                        </el-menu-item>
                    </a>
                </template>
            </el-menu>
        </div>
    </div>
</template>
<script>
    export default {
        name: "jy-menu",
        props: {
            backgroundColor: {
                type: String,
                default: "#edf0f5"
            },
            activeTextColor: {
                type: String,
                default: "#05265f"
            },
            TextColor: {
                type: String,
                default: "#fff"
            },
            mode: {
                type: String,
                default: "vertical" //horizontal
            },
            menu: {
                type: Array,
                default: function () {
                    return [];
                }
            }
        },
        data() {
            return {};
        },
        activated() {
            // this.getUserMenu()
        },
        watch: {},
        methods: {}
    };
</script>
<style lang="scss" scoped="admin_nav">
    @import "../config/variable.scss";

    .admin_nav {
        &.vertical {
            height: 100%;
            background-color: $menu_bg;
            overflow-y: scroll;
            margin-right: -20px;

            .nav_box {
                width: 100%;

                .el-menu {
                    border: none;

                    .is-alive {
                        // background: rgb(67, 74, 80) !important;
                    }
                }
            }
        }
        .nav_a {
            text-decoration: none;
        }
    }
</style>
<style lang="scss">
    @import "../config/variable.scss";

    .admin_nav {
        &.vertical {
            .el-menu-item,
            .el-submenu__title {
                &.is-active {
                    * {
                        color: $menu_active;
                    }
                }

                * {
                    color: #5b5b5b;
                    font-size: 18px;
                }
            }

            .el-submenu.is-active:not(.is-opened) .el-submenu__title,
            .el-menu-item.is-active {
                // background: rgb(67, 74, 80) !important;
                position: relative;

                i {
                    &.el-submenu__icon-arrow {
                        color: $menu_active;
                        font-weight: bold;
                    }
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 4px;
                    background: $menu_active;
                }
            }
        }
    }
</style>
