<template>
	<el-table-column v-if="!type" v-bind="propsObj" class-name="jy_table_column">
		<template slot-scope="scope">
			<slot :row="scope.row" :column="scope.column" :$index="scope.$index">
				<span class="cell_span">{{formatter(scope.row,scope.column,scope.row[prop],scope.$index)|empty}}</span>
			</slot>
		</template>
		<template slot="header" slot-scope="scope">
			<slot name="header" :row="scope.row" :column="scope.column" :$index="scope.$index">
				<span class="cell_span">{{label}}</span>
				<el-tooltip v-if="tip" class="item" effect="dark" :content="tip" placement="top">
					<i class="el-icon-question"></i>
				</el-tooltip>
			</slot>
		</template>
	</el-table-column>
	<el-table-column v-else v-bind="propsObj" class-name="jy_table_column"></el-table-column>
</template>
<script>
	const fixedLeftTypes = ["selection", "index"];
	export default {
		name: "jy-table-column",
		props: {
			tip: {
				type: String,
				default: ''
			},
			type: {
				type: String,
				default: "",
			},
			index: {
				type: [Number, Function],
				default: undefined,
			},
			label: {
				type: String,
				default: "",
			},
			columnKey: {
				type: String,
				default: undefined,
			},
			prop: {
				type: String,
				default: undefined,
			},
			width: {
				type: [String, Number],
				default: undefined,
			},
			minWidth: {
				type: [String, Number],
				default: undefined,
			},
			fixed: {
				type: [String, Boolean],
				default: undefined,
			},
			renderHeader: {
				type: Function,
				default: undefined,
			},
			sortable: {
				type: [String, Boolean],
				default: undefined,
			},
			sortNethod: {
				type: Function,
				default: undefined,
			},
			sortBy: {
				type: [Function, String, Array],
				default: undefined,
			},
			sortOrders: {
				type: Array,
				default: undefined,
			},
			formatter: {
				type: Function,
				default: function(row, column, cellValue){
					return cellValue
				},
			},
			resizable: {
				type: Boolean,
				default: true,
			},
			showOverflowTooltip: {
				type: Boolean,
				default: undefined,
			},
			align: {
				type: String,
				default: "center",
			},
			headerAlign: {
				type: String,
				default: "center",
			},
			className: {
				type: String,
				default: "",
			},
			labelClassName: {
				type: String,
				default: undefined,
			},
			selectable: {
				type: Function,
				default: undefined,
			},
			reserveSelection: {
				type: Boolean,
				default: undefined,
			},
			filters: {
				type: Array,
				default: undefined,
			},
			filterPlacement: {
				type: String,
				default: undefined,
			},
			filterMultiple: {
				type: Boolean,
				default: undefined,
			},
			filterMethod: {
				type: Function,
				default: undefined,
			},
			filteredValue: {
				type: Array,
				default: undefined,
			},
		},
		computed: {
			fixedValue: function () {
				if (fixedLeftTypes.includes(this.type)) {
					return "left";
				} else {
					return this.fixed;
				}
			},
			propsObj: function () {
				let width =
					this.width !== undefined ? String(this.width) : undefined;
				let minWidth =
					this.minWidth !== undefined ? String(this.minWidth) : undefined;

				let obj = {
					type: this.type,
					index: this.index,
					label: this.label,
					columnKey: this.columnKey,
					prop: this.prop,
					width,
					minWidth,
					fixed: this.fixedValue,
					renderHeader: this.renderHeader,
					sortable: this.sortable,
					sortNethod: this.sortNethod,
					sortBy: this.sortBy,
					sortOrders: this.sortOrders,
					formatter: this.formatter,
					resizable: this.resizable,
					showOverflowTooltip: this.showOverflowTooltip,
					align: this.align,
					headerAlign: this.headerAlign,
					className: this.className,
					labelClassName: this.labelClassName,
					selectable: this.selectable,
					reserveSelection: this.reserveSelection,
					filters: this.filters,
					filterPlacement: this.filterPlacement,
					filterMultiple: this.filterMultiple,
					filterMethod: this.filterMethod,
					filteredValue: this.filteredValue,
				};
				for (let key in obj) {
					if (obj[key] === undefined) {
						delete obj[key];
					}
				}
				return obj;
			},
		},
		filters: {
			empty: function (v) {
				if (v === null || v === undefined || v === "") {
					return "-";
				} else {
					return v;
				}
			},
		},
	};
</script>
<style lang="scss">
	.el-table {
		tr {
			td {
				&.jy_table_column {
					.cell_span {
						display: inline-block;
						text-align: left;
					}
				}
			}

			th {
				&.jy_table_column {
					background: #4077bc !important;
					color: #fff;
					text-align: center;
				}

				&.bg_color_2 {
					background: #f2f2f2;
					color: #979797;
				}
			}
		}
	}
</style>
