<template>
    <div class="import_com">
        <jy-dialog :title="title" :visible.sync="showDialog" type="table" width="800px" @closeed="close">
            <div v-if="showDialog">
                <el-steps :active="active" finish-status="success" simple>
                    <el-step v-for="one in stupList" v-bind:key="one.name" :title="one.name"></el-step>
                </el-steps>
                <div class="content_box" v-show="active == 0">
                    <div v-show="isUploading" class="uploading">
                        <i class="el-icon-loading"></i>
                    </div>
                    <div v-show="!isUploading" v-for="one in udList" v-bind:key="one.icon" class="up-down_item">
                        <i-cont
                            :comType="one.comType"
                            @failUpload="failUpload"
                            :icon="one.icon"
                            :title="one.title"
                            :butText="one.butText"
                            :dis="one.dis"
                            @butFun="
                                () => {
                                    one.butFun && one.butFun();
                                }
                            "
                            :ref="one.comType"
                            :headers="headers"
                            :action="one.action"
                            :fileSize="one.fileSize"
                            :fileType="one.fileType"
                        ></i-cont>
                    </div>
                </div>
                <div class="content_box" v-show="active == 1">
                    <div>
                        <i-cont icon="el-icon-warning">
                            <div class="warn_msg">
                                <p>
                                    正常数据条数：
                                    <span>{{ successNumber }}条</span>
                                </p>
                                <p class="warn_text">
                                    异常数据条数：
                                    <span>{{ errList && errList.length }}条</span>
                                </p>
                            </div>
                        </i-cont>
                        <div class="error_list" v-if="errList && errList.length">
                            <div class="title">异常提示：</div>
                            <div class="msg_list">
                                <div class="msg_list_item" v-for="one in errList" v-bind:key="one.lat + one.lng">
                                    {{ one.error }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="remind_box">
                                <span>注意</span>
                                <p v-if="(successList && successList.length) || successLength">点击【下一步】则保存正常数据</p>
                                <p v-else>没有正常数据，不给予导入</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content_box" v-show="active >= 2">
                    <div class="finally_box">
                        <i class="el-icon-error" v-if="fail"></i>
                        <i class="el-icon-loading" v-else-if="active == 2"></i>
                        <i class="el-icon-success" v-if="active == 3"></i>
                        <p class="finally_mind">{{ finallyMind }}</p>
                        <p class="finally_s_mind">成功导入 {{ successNumber }} 条数据</p>
                    </div>
                </div>
                <div class="button_box">
                    <el-button type="primary" v-on:click="pre" v-if="active > 0 && (active < 2 || fail)">上一步</el-button>
                    <el-button v-on:click="close">{{ cancelText }}</el-button>
                    <el-button type="primary" v-on:click="next" v-if="active < 2" :disabled="nextDisabled">下一步</el-button>
                </div>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
    import iCont from "./i-cont-ui";
    const stupList = [{ name: "上传文件" }, { name: "数据验证" }, { name: "导入数据" }];
    export default {
        name: "jy-import",
        props: {
            title: {
                type: String,
                default: "导入"
            },
            stupList: {
                type: Array,
                default: function () {
                    return stupList;
                }
            },
            action: {
                type: String,
                default: ""
            },
            headers: {
                type: Object
            },
            downTitle: {
                type: String,
                default: "填写导入数据信息"
            },
            upTitle: {
                type: String,
                default: "上传填好的信息表"
            },
            downDis: {
                type: String,
                default: "请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头不能删除"
            },
            upDis: {
                type: String,
                default: "文件后缀名必须为 xls 或 xlsx(即 Excel 格式)，文件大小不得大于5M，最多支持导入 1000 条数据"
            },
            successLength: {
                type: [String, Number]
            },
            successList: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            errList: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            status: {
                type: Number,
                default: 0 //0--runing, -1--fail, 1--success
            },
            fileSize: {
                type: Number,
                default: 5
            }
        },
        data() {
            return {
                isUploading: false,
                showDialog: false,
                active: 0,
                udList: [],
                timestamp: null,
                wastesList: [],
                finallyMind: ""
            };
        },
        components: {
            iCont
        },
        watch: {
            status: function (val) {
                if (val == 1) {
                    this.next();
                    this.finallyMind = "导入成功";
                } else if (val == -1) {
                    this.finallyMind = "导入失败";
                } else {
                    this.finallyMind = "";
                }
            }
        },
        computed: {
            nextDisabled: function () {
                if (this.active == 1) {
                    return !this.successList.length && !this.successLength;
                } else {
                    return false;
                }
            },
            cancelText: function () {
                let str = "取消";
                if (this.active == 3) {
                    str = "关闭";
                }
                return str;
            },
            fail: function () {
                return this.status == -1;
            },
            successNumber: function () {
                return this.successLength || (this.successList && this.successList.length);
            }
        },
        created() {
            this.setUdList();
        },
        methods: {
            init() {
                this.active = 0;
                this.$emit("update:status", 0);
                this.showDialog = true;
                this.$nextTick(() => {
                    let uploadRef = this.$refs.upload;
                    if (uploadRef instanceof Array) {
                        this.$refs.upload[0].updateCom();
                    } else {
                        this.$refs.upload.updateCom();
                    }
                });
            },
            setUdList() {
                let list = [
                    {
                        icon: "el-icon-download",
                        title: this.downTitle,
                        dis: this.downDis,
                        butText: "下载模板",
                        comType: "download",
                        butFun: () => {
                            this.$emit("downloadTemplate");
                        }
                    },
                    {
                        icon: "el-icon-upload2",
                        title: this.upTitle,
                        dis: this.upDis,
                        butText: "上传文件",
                        comType: "upload",
                        action: this.action,
                        headers: this.uploadHeaders,
                        fileSize: this.fileSize,
                        fileType: ["xlsx", "xlsm", "xls"]
                    }
                ];
                this.udList = list;
            },
            next() {
                if (this.active == 0) {
                    this.isUploading = true;
                    let uploadRef = this.$refs.upload;
                    if (uploadRef instanceof Array) {
                        this.$refs.upload[0].uploadFun(data => {
                            this.isUploading = false;
                            if (data) {
                                this.active++;
                                this.$emit("getElxData", data);
                            } else {
                                this.$message({
                                    type: "warning",
                                    message: "请上传文件"
                                });
                            }
                        });
                    } else {
                        this.$refs.upload.uploadFun(data => {
                            this.isUploading = false;
                            if (data) {
                                this.active++;
                                this.$emit("getElxData", data);
                            } else {
                                this.$message({
                                    type: "warning",
                                    message: "请上传文件"
                                });
                            }
                        });
                    }
                } else if (this.active == 1) {
                    this.active++;
                    this.$emit("confirm");
                } else {
                    this.active++;
                }
            },
            failUpload() {
                this.isUploading = false;
            },
            pre() {
                this.active--;
            },
            close() {
                this.showDialog = false;
                this.active = 0;
            }
        }
    };
</script>
<style lang="scss" scoped="import_com">
    .import_com {
        .content_box {
            padding: 20px 0;

            .up-down_item {
                + .up-down_item {
                    margin-top: 10px;
                }
            }

            .remind_box {
                padding: 20px;
                font-size: 16px;

                p {
                    font-size: 14px;
                    color: #ff8989;
                    margin-top: 10px;
                }
            }

            .radio_box {
                padding: 10px 20px;

                &:nth-last-of-type(1) {
                    padding-bottom: 20px;
                }
            }

            .warn_msg {
                p {
                    span {
                        color: #67c23a;
                    }

                    &.warn_text {
                        span {
                            color: #ff8989;
                        }
                    }
                }
            }

            .error_list {
                margin-top: 20px;
                padding: 20px;
                border: 1px solid #cdcdcd;

                .title {
                    font-size: 16px;
                    padding-bottom: 20px;
                    font-weight: bold;
                }

                .msg_list {
                    .msg_list_item {
                        padding-bottom: 10px;
                    }
                }
            }

            .finally_box {
                height: 300px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;

                i {
                    font-size: 60px;

                    &.el-icon-success {
                        color: #67c23a;
                    }

                    &.el-icon-error {
                        color: #f44336;
                    }
                }

                .finally_mind {
                    margin-top: 10px;
                    font-size: 18px;
                    letter-spacing: 2px;
                    font-weight: bold;
                    color: #323232;
                    text-align: center;
                    margin-bottom: 8px;
                }

                .finally_s_mind {
                    margin: 0;
                    font-size: 12px;
                    color: #979797;
                }
            }

            .uploading {
                text-align: center;
                i {
                    font-size: 50px;
                }
            }
        }

        .button_box {
            text-align: center;
            margin-top: 10px;
            padding: 10px 0 0;
            border-top: 1px solid #cdcdcd;
        }
    }
</style>
