<template>
	<div class="jy_pagination">
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="PAGEINDEX" :page-sizes="pageSizes" :page-size="pageSize" :layout="layout" :total="total" background></el-pagination>
	</div>
</template>
<script>
export default {
	name: 'jy-pagination',
	props: {
		currentPage: {
			type: Number,
			default: 1,
		},
		pageIndex: {
			type: Number,
			default: 0,
		},
		pageSizes: {
			type: Array,
			default: function() {
				let list = [10, 20, 50, 100];
				if (this.global && this.global.pageSizesList) {
					list = this.global.pageSizesList;
				}
				return list
			},
		},
		layout: {
			type: String,
			default: function() {
				let layout = 'total, sizes, prev, pager, next, jumper';
				if (this.global && this.global.pageLayout) {
					layout = this.global.pageLayout;
				}
				return layout
			},
		},
		pageSize: {
			type: Number,
			default: 10,
		},
		total: {
			type: Number,
			default: 0,
		}
	},
	computed: {
		PAGEINDEX: function() {
			let pageIndex = 1;
			if (this.pageIndex !== 0) {
				pageIndex = this.pageIndex;
			} else {
				pageIndex = this.currentPage;
			}
			return pageIndex;
		},
	},
	methods: {
		handleSizeChange(size) {
			this.$emit('size-change', size)
			this.$emit('sizeChange', size)
			this.$emit('pageSizeChange', size)
		},
		handleCurrentChange(pageIndex) {
			this.$emit('current-change', pageIndex)
			this.$emit('currentChange', pageIndex)
			this.$emit('pageIndexChange', pageIndex)
		},
	}
}

</script>
<style lang="scss" scoped="jy_pagination">
.jy_pagination {
	text-align: right;
	margin-top: 20px;

	.el-pagination {
		display: inline-block;
	}
}

</style>
