<template>
    <div class="jy_query_com" :class="{ card_com: card }">
        <el-form :label-width="formLebelWidth" :model="model" ref="queryForm" :size="size">
            <div class="jy_query_flex_box">
                <div class="jy_query_flex_left" v-if="showArrow">
                    <span class="jy_query_flex_left_arrow" v-on:click="toggleLimitHeight">
                        <i class="el-icon-arrow-down" :class="{ up: !limitHeight }"></i>
                    </span>
                </div>
                <div class="jy_query_flex_right">
                    <el-row>
                        <el-col :span="queryItemSpan" class="query_items_box" :class="{ limit_height: limitHeight }" :style="queryItemsBoxStyle">
                            <el-row>
                                <slot></slot>
                            </el-row>
                        </el-col>
                        <el-col :span="buttonSpan">
                            <el-form-item class="button_form_item" label-width="20px">
                                <slot name="rightCol"></slot>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-form>
    </div>
</template>
<script>
export default {
    name: "jy-query",
    props: {
        buttonSpan: {
            type: [Number, String],
            default: 6
        },
        labelWidth: {
            type: [Number, String],
            default: "90px"
        },
        model: {
            type: Object,
            default: function () {
                return {};
            }
        },
        card: {
            type: Boolean,
            default: false
        },
        canHidden: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "small"
        }
    },
    watch: {
        canHidden: {
            handler: function (val) {
                if (val) {
                    this.showArrow = true;
                    this.limitHeight = true;
                    this.$nextTick(() => {
                        this.setQueryItemsBoxStyle();
                    });
                } else {
                    this.limitHeight = false;
                    this.queryItemsBoxStyle = {};
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            showArrow: false,
            limitHeight: false,
            queryItemsBoxStyle: {}
        };
    },
    computed: {
        queryItemSpan: function () {
            let buttonSpan = Number(this.buttonSpan) || 6;
            return 24 - buttonSpan;
        },
        formLebelWidth: function () {
            let n = Number(this.labelWidth.replace("px", "")) || 80;
            return n + "px";
        }
    },
    methods: {
        resetFields() {
            this.$refs.queryForm.resetFields();
        },
        toggleLimitHeight(b) {
            if (typeof b == "boolean") {
                this.limitHeight = b;
            } else {
                this.limitHeight = !this.limitHeight;
            }
        },
        setQueryItemsBoxStyle() {
            let items = this.$slots.default;

            items = items.map(one => one.componentInstance).filter(one => one);

            let colSpans = items.map(one => one.colSpan);

            let rows = 1;
            let colSpanSum = 0;
            colSpans.forEach(one => {
                if (colSpanSum >= 24) {
                    rows++;
                    colSpanSum = 0;
                }
                colSpanSum += one;
            });

            let itemHeight = 50;
            items.forEach(one => {
                let h = $(one.$el).height();
                if (h < itemHeight) {
                    itemHeight = h;
                }
            });

            if (rows > 1) {
                this.showArrow = true;
            } else {
                this.showArrow = false;
            }

            this.queryItemsBoxStyle = {
                height: itemHeight * rows + "px"
            };
        }
    }
};
</script>
<style lang="scss">
.jy_query_com {
    &.card_com {
        padding: 15px 15px 0;
        background: #fff;
        margin: 10px 20px;
        box-shadow: 0 0 6px 0 #cfcfcf;
        border-radius: 4px;
    }

    .jy_query_flex_box {
        display: flex;

        .jy_query_flex_left {
            .jy_query_flex_left_arrow {
                cursor: pointer;

                i {
                    transition: transform 0.5s;
                    font-size: 14px;

                    &.up {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .jy_query_flex_right {
            flex: 1;
        }
    }

    .query_items_box {
        transition: height 0.5s;
        overflow: hidden;

        &.limit_height {
            height: 50px !important;
        }
    }

    .el-form-item {
        margin-bottom: 14px;
    }

    .button_form_item {
        text-align: right;

        * + .el-row {
            .el-button {
                margin-top: 14px;
            }
        }
    }
}
</style>
